<style type="text/css">
  .success-message {
    background-color: forestgreen;
    color: white;
    font-size: 14px;
    border-radius: 3px;
    padding:10px;
  }
</style>

<div class="se-pre-con" *ngIf="isLoad"></div>
<div id="router">
  <div class="container-fluid grad-bg" id="top-newblock">
    <div class="container-fluid">
      <div class="row align-items-bottom justify-content-center text-center">
        <div class="col-lg-8">
          <h2 id="jobTitle" class="white-color">Hi, {{this.candidateModel.FullName}}!</h2>
          <hr>
          <div class="row">
            <div class="col white-color top-cat">
              <p>
                <strong> <i class="material-icons primary-color">filter_list</i></strong>&nbsp;{{this.candidateModel.JobOrderTitle}}
              </p>
            </div>
            <div class="col white-color top-cat">
              <p>
                <strong> <i class="material-icons primary-color">work</i></strong>&nbsp;{{this.candidateModel.ClientCorporation}}
              </p>
            </div>
            <div class="col white-color top-cat">
              <p>
                <strong> <i class="material-icons primary-color">pin_drop</i></strong>&nbsp;{{this.candidateModel.AddressCity}} {{this.candidateModel.AddressState}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <a class="not-mobile" ui-sref="jobsBoard"><p class="bottom-aligned-text white-color top-cat"><i class="material-icons primary-color">arrow_back</i> Back</p></a> -->
  </div>

  <div class="container-fluid">
    <div id="content-area" class="row">

      <div id="filters-section" class="col-lg" style="margin-left: -35px; padding: 0px; min-width: 0px; max-width: 0px;">

      </div>

      <div id="content-section" class="col-lg">


        <div class="row justify-content-center">
          <div class="col-lg" style="margin-top:10px !important;">
            <div class="container">
              <h3 class="acc-color">Privacy</h3>
              <p align="justify">Globalpundits is an equal opportunity employer. As required by law, we must record certain information to be made a part of our affirmative action program.         Applicants for employment are invited to participate in the affirmative action program by reporting their status as a protected veteran or other minority. In extending this invitation, we advise you that: </p>
              <div style="font-size:14px;">
                <ul>
                  <li>Workers (applicants) are under no obligation to respond but may do so in the future if they choose</li>
                  <li>Responses will remain confidential within the human resource department</li>
                  <li>Responses will be used only for the necessary information to include in our affirmative action program. We are a company that values diversity. We actively encourage women, minorities, veterans and disabled employees to apply. Refusal to provide this information will have no bearing on your application and will not subject you to any adverse treatment.</li>
                </ul>

                <p><b>EEOC RACE/ETHNIC IDENTIFICATION CATEGORIES</b></p>
                <ul>
                  <li>
                    Hispanic or Latino - A person of Cuban, Mexican, Puerto Rican, South or Central American, or other Spanish culture or origin regardless of race.
                  </li>
                  <li>
                    White (not Hispanic or Latino) - A person having origins in any of the original peoples of Europe, the Middle East or North Africa.
                  </li>
                  <li>
                    Black or African American (not Hispanic or Latino) - A person having origins in any of the black racial groups of Africa.
                  </li>
                  <li>
                    Native Hawaiian or Other Pacific Islander (not Hispanic or Latino) - A person having origins in any of the peoples of Hawaii, Guam, Samoa or other Pacific Islands.
                  </li>
                  <li>
                    Asian (not Hispanic or Latino) - A person having origins in any of the original peoples of the Far East, Southeast Asia or the Indian Subcontinent, including, for example, Cambodia, China, India, Japan, Korea, Malaysia, Pakistan, the Philippine Islands, Thailand and Vietnam.
                  </li>
                  <li>
                    American Indian or Alaska Native (not Hispanic or Latino) - A person having origins in any of the original peoples of North and South America (including Central America) and who maintain tribal affiliation or community attachment.
                  </li>
                  <li>
                    Two or more races (not Hispanic or Latino) - All persons who identify with more than one of the above races.
                  </li>
                </ul>

                <p><b>PROTECTED VETERAN DEFINITION</b></p>
                <ul>
                  <li>
                    Protected veteran means a veteran who may be classified as an active-duty wartime or campaign badge veteran, disabled veteran, Armed Forces service medal veteran or recently separated veteran
                  </li>
                  <li>
                    Active-duty wartime or campaign badge veteran means a veteran who served on active-duty in the U.S. military, ground, naval or air service during a war or in a campaign or expedition for which a campaign badge has been authorized under the laws administered by the Department of Defense.
                  </li>
                  <li>
                    Armed Forces service medal veteran means any veteran who, while serving on active-duty in the U.S.  military, ground, naval or air service, participated in a United States military operation for which an Armed Forces service medal was awarded pursuant to Executive Order 12985 (61 FR 1209, 3 CFR, 1996 Comp., p. 159).
                  </li>
                  <li>
                    Disabled veteran means (1) a veteran of the U.S. military, ground, naval or air service who is entitled to compensation (or who but for the receipt of military retired pay would be entitled to compensation) under laws administered by the Secretary of Veterans Affairs, or (2) a person who was discharged or released from active duty because of a service-connected disability.
                  </li>
                  <li>
                    Recently separated veteran means a veteran during the three-year period beginning on the date of such veteran's discharge or release from active duty in the U.S. military, ground, naval or air service.
                  </li>
                </ul>
              </div>


            </div>
          </div>

          <div class="col-lg-4 grey-bg">
            <br>
            <div class="white-bg">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg">
                    <div class="col-lg-10 success-message" *ngIf="showSuccessMessage">
                      Thanks for updating your details!
                    </div>
                    <form [formGroup]="candidateForm" id="jobForm">

                      <div id="sf1" class="frm">
                        <!--  <fieldset> -->

                        <select class="form-control kt-selectpicker col-md-10" formControlName="gender" style="color:#696969;">
                          <option value="null" disabled>-- Select Gender --</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                          <option value="D">I have chosen not to self-identify</option>
                        </select>
                        <select class="form-control kt-selectpicker col-md-10" formControlName="ethnicity" style="color:#696969;">
                          <option value="null" disabled>-- Select Ethnicity --</option>
                          <option value="Hispanic or Latino">Hispanic or Latino</option>
                          <option value="White">White</option>
                          <option value="Black or African American">Black or African American</option>
                          <option value="Native Hawaiian or Pacific Islander">Native Hawaiian or Pacific Islander</option>
                          <option value="Asian">Asian</option>
                          <option value="American Indian/Alaskan Native">American Indian/Alaskan Native</option>
                          <option value="Two or more races">Two or more races</option>
                          <option value="I have chosen not to self-identify">I have chosen not to self-identify</option>
                        </select>
                        <select class="form-control kt-selectpicker col-md-10" formControlName="veteran" style="color:#696969;">
                          <option value="null" disabled>-- Select Veteran --</option>
                          <option value="Y">Yes</option>
                          <option value="N">No</option>
                          <option value="D">I have chosen not to self-identify</option>
                        </select>
                        <select class="form-control kt-selectpicker col-md-10" formControlName="disablity" style="color:#696969;">
                          <option value="null" disabled>-- Select Disablity --</option>
                          <option value="Y">Yes</option>
                          <option value="N">No</option>
                          <option value="D">I have chosen not to self-identify</option>
                        </select>



                      </div>


                      <div class="clearfix" style="height: 10px;clear: both;"></div>
                      <div class="form-group">
                        <div align="right">
                          <div class="col-lg-10 col-lg-offset-2">

                            <button type="button" class="btn btn-brand btn-elevate btn-icon-sm" style="float:right" [disabled]="!candidateForm.valid"
                                    (click)="onSubmit()">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>


                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>

</div>
