export class CandidateDetailsModel{
    Id:number;
    BH_Id:number;
    FullName:string;
    JobOrderId:string;
    JobOrderTitle:string;
    AddressCity:string;
    AddressState:string;
    ClientCorporation:string;
    CandidateId:number;
    Ethnicity:string;
    Veteran:string;
    Gender:string;
    Disablity:string;
}
