import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule, } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
//services
import { LoginService } from './services/login.service';
import { CandidateDetailsService } from './services/candidate-details.service';
//components
import { CandidateDetailsComponent } from './candidate-details/components/candidate-details.component';
import { CandidateComponent } from './candidate-details/components/candidate.component';

const appRoutes: Routes = [
  { path: 'candidate-details/:id', component: CandidateDetailsComponent },
  { path: 'candidate/:id', component: CandidateComponent },
]

@NgModule({
  declarations: [
    AppComponent,
    CandidateDetailsComponent,
    CandidateComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [LoginService, CandidateDetailsService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
