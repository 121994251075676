import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
//service-models
import { GpRequest } from '../service-models/Request/gpRequest';
import { CommonRequest } from '../service-models/Request/CommonRequest';
import { CandidateDetailsFetchResponse } from '../service-models/Response/CandidateDetailsFetchResponse';
import { CandidateDetailsUpdateRequest } from '../service-models/Request/CandidateDetailsUpdateRequest';
import { CommonResponse } from '../service-models/Response/CommonResponse';

// models

@Injectable()
export class CandidateDetailsService {
  baseUrl = "https://gp-api.yathisolutions.com/v1/";
  ecsRequest: GpRequest;

  constructor(public _http: HttpClient) {

    this.ecsRequest = new GpRequest();
    this.ecsRequest.UserId = 1;
    //to fetch IP address
    this._http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ecsRequest.IpAddress = res.ip;
    });
  }
  getHeaders() {
    var requestHeaders = new HttpHeaders();
    requestHeaders.append('Content-Type', 'application/json');
    return requestHeaders;
  }

  public getCandidateDetailsById(commonRequest: CommonRequest): Observable<CandidateDetailsFetchResponse> {
    const headerDirect = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
    }
    return this._http.post<CandidateDetailsFetchResponse>(this.baseUrl + "candidate-details/fetch-by-id",
      JSON.stringify(commonRequest), { headers: headerDirect });
  }
  public updateCandidateDetailsById(candidateDetailsUpdateRequest: CandidateDetailsUpdateRequest): Observable<CommonResponse> {
    const headerDirect = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
    }
    return this._http.post<CommonResponse>(this.baseUrl + "candidate-details/update",
      JSON.stringify(candidateDetailsUpdateRequest), { headers: headerDirect });
  }
}
