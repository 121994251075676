import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonRequest } from '../../service-models/Request/CommonRequest';
import { CandidateDetailsFetchResponse } from '../../service-models/Response/CandidateDetailsFetchResponse';
import { CandidateDetailsModel } from '../../models/Models/CandidateDetailsModel';
import { CandidateDetailsService } from '../../services/candidate-details.service';
import { CommonResponse } from '../../service-models/Response/CommonResponse';
import { CandidateDetailsUpdateRequest } from '../../service-models/Request/CandidateDetailsUpdateRequest';
import { ActivatedRoute } from '@angular/router';
@Component({
    templateUrl: '../templates/candidate-details.component.html'
})

export class CandidateDetailsComponent implements OnInit {
    // to display loader when request sends to the server
    isLoad: boolean = false;
    // from Submitting
    isFormSubmitted: boolean = false;

    //form builder
    candidateForm: FormGroup;
    //service-call to fetch by id
    commonRequest: CommonRequest;
    candidateDetailsFetchResponse: CandidateDetailsFetchResponse;
    candidateModel: CandidateDetailsModel;

    //service-call to update
    commonResponse: CommonResponse;
    candidateDetailsUpdateRequest: CandidateDetailsUpdateRequest;
    candidate: CandidateDetailsModel;


    constructor(private _formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private _candidateDetailsService: CandidateDetailsService) {
        this.candidate = new CandidateDetailsModel();
    }
    ngOnInit() {
        this.candidateForm = this._formBuilder.group({
            gender: [null, Validators.required],
            ethnicity: [null, Validators.required],
            veteran: [null, Validators.required],
            disablity: [null, Validators.required],
        });
        this.getDetails();
    }

    //Methods for validation
    isFieldValid(field: string) {
        return (
            (!this.candidateForm.get(field).valid && this.candidateForm.get(field).touched) ||
            (this.candidateForm.get(field).untouched && this.isFormSubmitted)
        );
    }
    onSubmit() {
        this.isLoad = true;
        this.candidateDetailsUpdateRequest = new CandidateDetailsUpdateRequest();
        this.candidate.Id = parseInt(this.activatedRoute.snapshot.params.id);
        this.candidate.Gender = this.candidateForm.controls["gender"].value;
        this.candidate.Ethnicity = this.candidateForm.controls["ethnicity"].value;
        this.candidate.Veteran = this.candidateForm.controls["veteran"].value;
        this.candidate.Disablity = this.candidateForm.controls["disablity"].value;
        this.candidateDetailsUpdateRequest.Candidate = this.candidate;
        this.commonResponse = new CommonResponse();

        this._candidateDetailsService.updateCandidateDetailsById(this.candidateDetailsUpdateRequest).subscribe(data => {
            this.commonResponse = data;
            if (this.commonResponse.Response.Status == 1) {
                alert(this.commonResponse.Response.Message);
                this.candidateForm.reset();
                this.isLoad = false;
            }
            else {
                this.isLoad = false;
                alert(this.commonResponse.Response.Message);
            }
        });
    }

    getDetails() {
        this.isLoad = true;
        this.commonRequest = new CommonRequest();
        this.commonRequest.Id = this.activatedRoute.snapshot.params.id;
        this.candidateDetailsFetchResponse = new CandidateDetailsFetchResponse();
        this._candidateDetailsService.getCandidateDetailsById(this.commonRequest).subscribe(data => {
            this.candidateDetailsFetchResponse = data;
            if (this.candidateDetailsFetchResponse.CandidateDetails != null) {
                this.candidateModel = this.candidateDetailsFetchResponse.CandidateDetails;
                this.isLoad = false;
            }
            else {
                this.isLoad = false;
                alert('No data found');
            }
        });
    }
}
