import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
//service-models
import { GpRequest } from '../service-models/Request/gpRequest';

// models

@Injectable()
export class LoginService {

    ecsRequest: GpRequest;

    constructor(public _http: HttpClient) {

        this.ecsRequest = new GpRequest();
        this.ecsRequest.UserId = 1;
        //to fetch IP address
        this._http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
            this.ecsRequest.IpAddress = res.ip;
        });
    }
    getHeaders() {
        var requestHeaders = new HttpHeaders();
        requestHeaders.append('Content-Type', 'application/json');

        return requestHeaders;
    }

    // public checkLoginCredentials(loginRequest: LoginRequest): Observable<LoginResponse> {
    //     loginRequest.Request = this.ecsRequest;
    //     const headerDirect = {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json',
    //         'Access-Control-Allow-Headers': 'Content-Type'
    //     }
    //     return this._http.post<LoginResponse>(this.baseUrl + "login",
    //         JSON.stringify(loginRequest), { headers: headerDirect });
    // }
}
