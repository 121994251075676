<!-- end:: Header -->
<div class="se-pre-con" *ngIf="isLoad"></div>
<div class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">

    <!-- begin:: Subheader -->
    <div class="kt-subheader   kt-grid__item" id="kt_subheader">
        <div class="kt-container  kt-container--fluid ">
            <div class="kt-subheader__main">

            </div>
        </div>
    </div>

    <!-- end:: Subheader -->
    <!-- begin:: Content -->
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet">

            <div class="kt-container-grid  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div class="kt-portlet kt-portlet--mobile">
                    <div class="kt-portlet__body">
                        <form [formGroup]="candidateForm">
                            <div class="form-group kt-form__group row">
                                <div class="form-group col-md-4 kt-margin-bottom-20-mobile"></div>
                                <div class="form-group col-md-4 kt-margin-bottom-20-mobile">
                                    <h1> Hi {{this.candidateModel.FullName}} </h1>
                                </div>
                            </div>
                            <div class="form-group kt-form__group row">
                                <div class="form-group col-md-4 kt-margin-bottom-20-mobile"></div>
                                <div class="form-group col-md-4 kt-margin-bottom-20-mobile">
                                    <label class="label-color">
                                        Gender
                                        <span class="kt-font-danger"> *</span>
                                    </label>
                                    <select class="form-control kt-selectpicker" formControlName="gender">
                                        <option value="null" disabled>-- Select Gender --</option>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                        <option value="U">Unknown</option>
                                        <option value="O">Other</option>
                                        <option value="D">I have chosen not to self-identify</option>
                                    </select>
                                    <div *ngIf="isFieldValid('gender')" class="kt-font-danger">
                                        Select Gender
                                    </div>
                                </div>
                                <div class="form-group col-md-4 kt-margin-bottom-20-mobile"></div>
                                <div class="form-group col-md-4 kt-margin-bottom-20-mobile"></div>
                                <div class="form-group col-md-4 kt-margin-bottom-20-mobile">
                                    <label class="label-color">
                                        Ethnicity
                                        <span class="kt-font-danger"> *</span>
                                    </label>
                                    <select class="form-control kt-selectpicker" formControlName="ethnicity">
                                        <option value="null" disabled>-- Select Ethnicity --</option>
                                        <option value="Hispanic or Latino">Hispanic or Latino</option>
                                        <option value="White">White</option>
                                        <option value="Black or African American">Black or African American</option>
                                        <option value="Native Hawaiian or Pacific Islander">Native Hawaiian or Pacific Islander</option>
                                        <option value="Asian">Asian</option>
                                        <option value="American Indian/Alaskan Native">American Indian/Alaskan Native</option>
                                        <option value="Two or more races">Two or more races</option>
                                        <option value="Other">Other</option>
                                        <option value="I have chosen not to self-identify">I have chosen not to self-identify</option>
                                    </select>
                                    <div *ngIf="isFieldValid('ethnicity')" class="kt-font-danger">
                                        Select Ethnicity
                                    </div>
                                </div>
                                <div class="form-group col-md-4 kt-margin-bottom-20-mobile"></div>
                                <div class="form-group col-md-4 kt-margin-bottom-20-mobile"></div>
                                <div class="form-group col-md-4 kt-margin-bottom-20-mobile">
                                    <label class="label-color">
                                        Veteran
                                        <span class="kt-font-danger"> *</span>
                                    </label>
                                    <select class="form-control kt-selectpicker" formControlName="veteran">
                                        <option value="null" disabled>-- Select Veteran --</option>
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                        <option value="U">Unknown</option>
                                        <option value="O">Other</option>
                                        <option value="D">I have chosen not to self-identify</option>
                                    </select>
                                    <div *ngIf="isFieldValid('veteran')" class="kt-font-danger">
                                        Select Veteran
                                    </div>
                                </div>
                                <div class="form-group col-md-4 kt-margin-bottom-20-mobile"></div>
                                <div class="form-group col-md-4 kt-margin-bottom-20-mobile"></div>
                                <div class="form-group col-md-4 kt-margin-bottom-20-mobile">
                                    <label class="label-color">
                                        Disablity
                                        <span class="kt-font-danger"> *</span>
                                    </label>
                                    <select class="form-control kt-selectpicker" formControlName="disablity">
                                        <option value="null" disabled>-- Select Disablity --</option>
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                        <option value="U">Unknown</option>
                                        <option value="O">Other</option>
                                        <option value="D">I have chosen not to self-identify</option>
                                    </select>
                                    <div *ngIf="isFieldValid('disablity')" class="kt-font-danger">
                                        Select Disablity
                                    </div>
                                </div>
                                <div class="form-group col-md-4 kt-margin-bottom-20-mobile"></div>
                                <div class="form-group col-md-4 kt-margin-bottom-20-mobile"></div>
                                <div class="form-group col-md-4 kt-margin-bottom-20-mobile">
                                    <div class="form-group" style="float:right">
                                        <label></label>
                                        <button type="button" class="btn btn-brand btn-elevate btn-icon-sm" style="float:right" [disabled]="!candidateForm.valid"
                                            (click)="onSubmit()">
                                            Submit
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <!-- end:: Content -->
        </div>

        <!--end: Form Wizard Step 1-->
        <!--end: Form Wizard Form-->

    </div>
</div>



<script>
    jQuery(document).ready(function () {
        TableAdvanced.init();
    });</script>