<div id="header" class="container-fluid">
	<a href="https://globalpundits.com/"><div id="logo-container" class="">
        <img id="header-logo" src="assets/images/gp_no_bg.png" alt="Main Logo">
	</div></a>
    <div id="header-links-container" class="row justify-content-around">
        <div id="header-links" class="col-lg align-self-center">
            <ul>
                <div id="header-link-bg"></div>
                <li class="menu-item"><a href="https://globalpundits.com/contact.php">Contact</a></li>
                <li class="menu-item">Employee
                    <div class="sub-menu sub-menu-nodesc sub-menu-right">
                        <ul>
                            <li class="submenu-item"><a href="https://myapps.paychex.com/landing_remote/login.do" target="_blank">Employee Login</a></li>
                            <li class="submenu-item"><a href="https://login.globalpundits.com" target="_blank">GP Portal</a></li>
                            <li class="submenu-item"><a href="https://globalpundits.com/files/Globalpundits Benefits Guide 2021.pdf" target="_blank">GP Benefits Guide 2021</a></li>
                        </ul>
                    </div>
                </li>
                <li class="menu-item">Services
                    <div class="sub-menu sub-menu-nodesc sub-menu-right">
                        <ul>
                            <li class="submenu-item"><a href="https://globalpundits.com/erp.php">Enterprise Professional Services</a></li>
                            <li class="submenu-item"><a href="https://globalpundits.com/education.php">Higher Education Services</a></li>
                            <li class="submenu-item"><a href="https://globalpundits.com/staff.php">Technical Staff Augmentation</a></li>
                            <li class="submenu-item"><a href="https://globalpundits.com/taas.php">Testing As A Service(TAAS)</a></li>
                        </ul>
                    </div>
                </li>
                <li class="menu-item">Referrals
                    <div class="sub-menu sub-menu-nodesc sub-menu-right">
                        <ul>
                            <li class="submenu-item"><a href="https://globalpundits.com/referral.php">New Referrals</a></li>
                            <li class="submenu-item"><a href="https://globalpundits.com/trackReferral.php">Track Status</a></li>
                        </ul>
                    </div>
                </li>
                <li class="menu-item"><a href="https://globalpundits.com/gp/careers/#/jobsBoard">Careers</a></li>
                <li class="menu-item"><a href="https://globalpundits.com/about.php">About Us</a></li>
                <li class="menu-item"><a href="https://globalpundits.com">Home</a></li>
            </ul>
        </div>
    </div>
    <div id="hamburger">
        <img src="assets/images/hamburger.png" alt="hamburger menu">
    </div>
	<div id="mobile-menu">
        <div id="mobile-menu-slide">
            <div id="mobile-menu-container">
                <ul>
                    <li class="mob-menu-link"><a href="https://globalpundits.com">Home</a></li>
                    <li class="mob-menu-link"><a href="https://globalpundits.com/about.php">About Us</a></li>
                    <li class="mob-menu-link"><a href="https://globalpundits.com/gp/careers/#/jobsBoard">Careers</a></li>
                    <li id="expandable-1" class="expandable mob-menu-link">Referrals
                        <div id="exp-container-1" class="expandable-container">
                            <a href="https://globalpundits.com/referral.php"><p>New Referral</p></a>
                            <a href="https://globalpundits.com/trackReferral.php"><p>Track Status</p></a>
                        </div>
                    </li>
                    <li id="expandable-2" class="expandable mob-menu-link">Services
                        <div id="exp-container-2" class="expandable-container">
                            <a href="https://globalpundits.com/erp.php"><p>Enterprise Professional Services</p></a>
                            <a href="https://globalpundits.com/education.php"><p>Higher Education Services</p></a>
                            <a href="https://globalpundits.com/staff.php"><p>Technical Staff Augmentation</p></a>
                            <a href="https://globalpundits.com/taas.php"><p>Testing As A Service (TAAS)</p></a>
                        </div>
                    </li>
                    <li id="expandable-3" class="expandable mob-menu-link">Employee
                        <div id="exp-container-3" class="expandable-container">
                            <a href="https://myapps.paychex.com/landing_remote/login.do" target="_blank"><p>Employee Login</p></a>
                            <a href="https://login.globalpundits.com"><p>GP Portal</p></a>
                            <a href="https://globalpundits.com/files/Globalpundits - 2021  Guide.pdf" target="_blank"><p>GP Benefits Guide 2021</p></a>
                        </div>
                    </li>
                    <li class="mob-menu-link"><a href="https://globalpundits.com/contact.php">Contact</a></li>
                </ul>
            </div>
        </div>
	</div>
</div>
<router-outlet></router-outlet>
<div id="footer" class="container-fluid">
  <div class="container">
    <div class="row justify-content-center align-items-top">
      <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="100" id="" class="col-lg sal-animate">
        <h3>Sitemap</h3>
        <ul class="footer-list">
          <li><a href="https://globalpundits.com/">Home</a></li>
          <li><a href="https://globalpundits.com/about.php">About Us</a></li>
          <li><a href="https://globalpundits.com/referral.php">Referrals</a></li>
          <li><a href="https://globalpundits.com/contact.php">Contact</a></li>
        </ul>
      </div>
      <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="200" id="" class="col-lg sal-animate">
        <h3>Employee Section</h3>
        <ul class="footer-list">
          <li><a href="https://myapps.paychex.com/landing_remote/login.do">Employee Login</a></li>
          <li><a href="https://login.globalpundits.com">GP Portal</a></li>
          <li><a href="https://globalpundits.com/files/Globalpundits Benefits Guide 2021.pdf" target="_blank">GP Benefits Guide 2021</a></li>
          <!-- <li><a href="taas.php">Testing As A Service</a></li> -->
        </ul>
      </div>
      <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="300" class="col-lg sal-animate">
        <h3>Contact Info</h3>
        <p class="footer-p"><strong><i class="material-icons">directions</i></strong>&nbsp; 4715D Sunset Blvd | Lexington SC 29072</p>
        <p class="footer-p"><strong><i class="material-icons">local_phone</i></strong>&nbsp; (803) 354-9400</p>
        <p class="footer-p"><strong><i class="material-icons">local_printshop</i></strong>&nbsp; (803) 996-1055</p>
        <p class="footer-p"><strong><i class="material-icons">email</i></strong>&nbsp;  info@globalpundits.com</p>
      </div>
      <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="400" class="col-lg sal-animate">
        <h3>Legal Info</h3>
        <ul class="footer-list">
          <li><a href="terms.php">Terms of Use</a></li>
          <li><a href="privacy.php">Privacy Statement</a></li>
        </ul>
        <hr>
        <img src="assets/images/image.png" alt="Oracle Gold Partner" style="width: 100px; height: auto">
      </div>
    </div>
  </div>

  <br>

  <div class="row">
    <div class="col">
      <hr>
      <p class="text-center">Globalpundits® 2019</p>
    </div>
  </div>
</div>
